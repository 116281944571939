@import '@ds/core/src/assets/plugins/plugins.bundle.css';
@import '@ds/plugins/assets/prismjs/prismjs.bundle.css';
@import '@ds/core/src/assets/style.bundle.css';
@import '@ds/core/src/assets/themes/header/base/padrao.css';
@import '@ds/core/src/assets/themes/header/menu/padrao.css';
@import '@ds/core/src/assets/themes/brand/light.css';
@import '@ds/core/src/assets/themes/aside/padrao.css';
@import '@ds/plugins/assets/fullcalendar/fullcalendar.bundle.css';
@import '@ds/plugins/assets/kanban/kanban.bundle.css';
@import '@ds/plugins/assets/cropper/cropper.bundle.css';
@import '@ds/plugins/assets/jstree/jstree.bundle.css';

@font-face {
	font-family: 'Montserrat-Regular';
	src: url('fonts/Montserrat-Regular.otf');
	font-weight: normal;
	font-style: normal;
}

body {
	font-family: 'Montserrat-Regular';
	background-color: #ebebeb;
}

.form-group input[type='text'] {
	width: 100%;
	border: 1px solid #00236c77;
	border-radius: 5px;
	height: 40px;
	font-size: 1.3rem;
}

.btn input[type='radio'] {
	display: none;
}

.number-btn {
	border-style: solid;
	border-radius: 10px;
}

.font-size-xl {
	font-size: 16px;
}

.required-field:after {
	content: '*';
	color: red;
	margin-left: 8px;
}

button[disabled] {
	pointer-events: none;
}

@media (min-width: 991.98px) {
	.animated:hover {
		transform: scale(1.4);
		transition: 0.2s ease;
	}

	.selected {
		transform: scale(1.4);
	}
}

.animated:hover {
	transform: scale(1.2);
	transition: 0.2s ease;
}

.selected {
	transform: scale(1.2);
}
